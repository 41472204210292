.body {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}

.hero_banner {
  scroll-snap-align: start;
}

.nav-menu li {
  text-transform: capitalize;
}

.footer-menu li {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer !important;
}

div::-webkit-scrollbar {
  display: none;
}

div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Century Gothic", "Times New Roman", Times, serif !important;
}

body {
  background-color: black;
  color: "#fff";
}
.gray {
  background-color: rgba(17, 17, 17, 0.6);
  color: "#fff";
}

.edu_wraper {
  background-color: #111;
  color: "#fff" !important;
}

.my_stylus {
  background-color: #fc3;
  padding: 15px;
  border-radius: 10;
  cursor: pointer;
}

.my_stylus :hover {
}

#my_video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.fixed_on_page_box {
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 99999999;
}

input,
textarea,
select {
  border-width: 2px !important;
  border-color: #ccc !important;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}
